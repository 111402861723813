html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print
{
  .no-print, .no-print * {
    display: none !important;
  }
  .page-break {
    display: block;
    page-break-before: always;
  }
}

.App-container {
  max-width: 960px;
  margin: auto;
  padding: 20px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

span {
  font-size: 16px;
}

.bold {
  font-weight: 600 !important;
}
.bolder {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.react-swipeable-view-container {
  width: 100vw;
}
