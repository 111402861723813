.App-container {
  max-width: 960px;
  margin: auto;
  padding: 20px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

span {
  font-size: 16px;
}

.bold {
  font-weight: 600 !important;
}
.bolder {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.react-swipeable-view-container {
  width: 100vw;
}